import React, { useCallback, useContext, useState } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ErrorAlert from 'src/components/general/ErrorAlert';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { SHOW_POCT_RESULTS_V2 } from 'src/featureFlags/currentFlags';
import { LabResultDialog } from 'src/labs/LabResultDialog';
import { LabResultDialogV2 } from 'src/labs/LabResultDialogV2';
import { OrderLogTable } from 'src/labs/OrderLogTable';
import { PatientSubmittedResultsTable } from 'src/labs/PatientSubmittedResultsTable';
import { PatientSubmittedResultsTableV2 } from 'src/labs/PatientSubmittedResultsTableV2';
import { PatientSubmittedTestResult } from 'src/labs/types';
import { UPDATE_LAB_RESULT } from 'src/labs/updateLabResult.gql';
import { useSWRPatientOrdersWithShipments } from 'src/labs/useSWRPatientOrdersWithShipments';
import { useSWRPatientSubmittedResults } from 'src/labs/useSWRPatientSubmittedResults';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

const PocTesting: React.FC<{ patientId: string }> = ({ patientId }) => {
  const resultsV2Enabled = useContext(FeatureFlagContext)[SHOW_POCT_RESULTS_V2];

  const [currentResult, setCurrentResult] = useState<PatientSubmittedTestResult | null>(null);
  const { apolloClient } = useContext(ApolloClientContext);

  const ordersAndShipments = useSWRPatientOrdersWithShipments(patientId);
  const testResults = useSWRPatientSubmittedResults(patientId);

  const handleEdit = useCallback(
    (rowData: PatientSubmittedTestResult) => {
      const result = testResults.data.find(labResult => labResult.result.id === rowData.result.id);
      setCurrentResult(result ?? null);
    },
    [testResults],
  );

  return (
    <>
      <PageTitle>Point of Care Testing</PageTitle>

      {testResults.error ? (
        <ErrorAlert
          message="Error loading patient-submitted test results."
          error={testResults.error}
        />
      ) : null}

      {resultsV2Enabled ? (
        <PatientSubmittedResultsTableV2
          data={testResults.data}
          isLoading={testResults.isLoading}
          onEdit={handleEdit}
        />
      ) : (
        <PatientSubmittedResultsTable
          data={testResults.data}
          isLoading={testResults.isLoading}
          onEdit={handleEdit}
        />
      )}

      {ordersAndShipments.error ? (
        <ErrorAlert
          message="Error loading patient orders with shipments."
          error={ordersAndShipments.error}
        />
      ) : null}
      <OrderLogTable
        data={ordersAndShipments.data}
        isLoading={ordersAndShipments.isLoading}
        patientId={patientId}
        onOrderPlaced={() => ordersAndShipments.mutate()}
      />

      {resultsV2Enabled ? (
        <LabResultDialogV2
          isOpen={!!currentResult}
          labResult={currentResult}
          onSave={async newValues => {
            await apolloClient?.mutate({
              mutation: UPDATE_LAB_RESULT,
              variables: {
                id: currentResult?.result.id,
                data: newValues,
              },
            });
            testResults.mutate();
            setCurrentResult(null);
          }}
          setCurrentResult={setCurrentResult}
        />
      ) : (
        <LabResultDialog
          isOpen={!!currentResult}
          labResult={currentResult}
          onSave={async newValues => {
            await apolloClient?.mutate({
              mutation: UPDATE_LAB_RESULT,
              variables: {
                id: currentResult?.result.id,
                data: newValues,
              },
            });
            testResults.mutate();
            setCurrentResult(null);
          }}
          setCurrentResult={setCurrentResult}
        />
      )}
    </>
  );
};

export default PocTesting;
