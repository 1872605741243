import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import pick from 'lodash/pick';
import React from 'react';

import { EditLabResultForm } from 'src/labs/EditLabResultForm';
import { PatientSubmittedTestResult } from 'src/labs/types';
import Colors from 'src/nightingale/Colors';

export const LabResultDialogV2: React.FC<{
  isOpen: boolean;
  labResult: PatientSubmittedTestResult | null;
  onSave: (values: Partial<PatientSubmittedTestResult['result']>) => Promise<void>;
  setCurrentResult: React.Dispatch<React.SetStateAction<PatientSubmittedTestResult | null>>;
}> = ({ isOpen, labResult, onSave, setCurrentResult }) => {
  const styles = useStyles();

  if (!labResult) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={() => setCurrentResult(null)}>
      <DialogTitle classes={{ root: styles.title }}>Edit Result</DialogTitle>
      <DialogContent>
        <EditLabResultForm
          images={labResult.images}
          item={pick(labResult.result, ['bupPositive', 'notes', 'verified'])}
          onSave={values => onSave(values)}
          onCancel={() => setCurrentResult(null)}
        />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    '& .MuiTypography-root': {
      color: Colors.Gray5,
      fontFamily: 'Tenor Sans',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.45,
      letterSpacing: 0.2,
    },
  },
});
